import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Button from './components/button';
import axios from 'axios';
//import App from './App';
//import reportWebVitals from './reportWebVitals';

const baseUrl = 'https://spot.3i.by/api/web';
//const baseUrl = 'https://reqres.in/api/users?page=2';


class Header extends React.Component{
	
	render(){
		//Header.defaultProps = { title : 'default'}
		let c = 'props net'
		if(this.props.title) c = this.props.title
		return(
			<p>{ c }</p>
		)
	}
}

class Arr extends React.Component{
	constructor(props){
		super(props)
		axios.get(baseUrl).then((res) => {
			console.log(res.data)
			this.setState({arrs: res.data})
		})
		this.state = {
			arrs: []
		}
	}
	render(){
		if(this.state.arrs.length > 0){
			return(
			<table >
				<thead>
						<tr>
							<td>id</td>
							<td>title</td>
							<td>content</td>
						</tr>
				</thead>
				<tbody>
				{this.state.arrs.map((el)=>(
						<tr key={el.id}>
							<td><a href={el.id}>{el.id}</a></td>
							<td>{ el.title }</td>
							<td>{ el.content }</td>
						</tr>
				))}
				</tbody>
			</table>
			)
		}else{
			return(
			<div className="">
			пользователей нет
			</div>
			)
		}
		
		
	}
}

class App extends React.Component{
	constructor(props){
		super(props)

		const d='3tt'
		this.state = {
			title: d,
		}
	}
	render(){
		const a = 'ttt';
		//App.defaultProps = { [title : "default"]}
		return(
		<div className="ppp">
			<Header title="пропс"/>
			<Header title="пропс2"/>
			<Header title={this.state.title}/>
			<p>{ a === 'ttt' ? 'проверочка' : 'no' }</p>
			<Button text='чтото'/>
			<p>{console.log(this.state.arr)}</p>
			<Arr arrs={this.state.arrs}/>
		</div>
		)
	}
}




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//  <React.StrictMode>
    <App />
//  </React.StrictMode>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
